import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import axios from "axios";
import {
  USER_QUERY,
  USER_SECONDARY_MAIL_UPDATE,
} from "../../../../components/graphql";
import { navigate } from "gatsby";
import { useQuery, useMutation } from "@apollo/client";
import BounceIndicator from "react-activity/lib/Bounce";
import SelectRole from "../../../../components/selectRole";
import cookies from "js-cookies";
import Cookie from "js-cookie";

function LoginRedirect() {
  const [open, setOpen] = useState(false);
  const [auth, setAuth] = useState();
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [secondaryMail, setSecondaryMail] = useState("");
  const location = useLocation();
  const [noJourneyContent, setNoJourneyContent] = useState(false);
  const [updatesecondaryEmailMutation] = useMutation(
    USER_SECONDARY_MAIL_UPDATE
  );

  console.log("location", location);

  const userData = useQuery(USER_QUERY, {
    variables: { id: userId },
  });

  const updatesecondaryEmail = async () => {
    try {
      await updatesecondaryEmailMutation({
        variables: {
          userId: userId,
          email: email,
        },
      }).then((secondaryResponse) => {
        console.log("secondaryResponse", secondaryResponse);
        setSecondaryMail(email);
      });
    } catch (e) {
      console.log("updatesecondaryEmail  error", e.message);
    }
  };

  useEffect(() => {
    if (!location) {
      return;
    }
    const { search } = location;
    var url = `${process.env.DEV_BACKEND_URL}/auth/google/callback${search}`;
    console.log("url", url);
    axios({
      method: "GET",
      url: url,
    })
      .then((res) => res.data)
      .then(setAuth)
      .catch(e => console.log(e))
  }, [location]);

  console.log(auth);
  if (auth) {
    if (userId === "") {
      setUserId(auth.user.id);
    }
    if (userData.data) {
      var userContent = userData.data.user;
      if (email === "") {
        setEmail(userContent.email);
      }
      console.log("userdata -------------", userData.data.user);
      if (secondaryMail === "") {
        updatesecondaryEmail();
      }
      if (userData.data.user.journeyStepContent.length === 0) {
        if (
          !cookies.getItem("username") &&
          !cookies.getItem("autoLogin") &&
          !cookies.getItem("email") &&
          !cookies.getItem("n_email")
        ) {
          console.log("register cookiesssssssssssssssssssssss", userContent);
          // cookies.setItem('n_uid',  localStorage.getItem("userId"),'/');
          // cookies.setItem('n_email', userContent.email, '/');
          // cookies.setItem('n_username', (userContent.email).split('@')[0], '/');
          Cookie.set("n_uid", localStorage.getItem("userId"), {
            path: "/",
          });
          Cookie.set("n_email", userContent.email, {
            path: "/",
          });
          Cookie.set("n_username", userContent.email.split("@")[0], {
            path: "/",
          });
        }
        console.log(
          "00000000000000000000######################",
          cookies.keys()
        );
         try {
          axios({
            method: "post",
            url: `${process.env.NODEBB_URL}/api/v3/users`,
            data: {
              _uid: "1",
              email: userContent.email,
              username: userContent.email.split("@")[0],
            },
            headers: {
              _uid: "1",
              Authorization: `Bearer ${process.env.NOBEBB_TOKEN}`,
            },
          })
          .then(function (response) {
            console.log("::::::::::::::>>>>>>>>>>>", response);
          })
          .catch(function (error) {
            console.log(error);
          })
        }
        catch (e) {
          console.log("create user error", e.message)
         }

        if (userData.data.user.userRole === null) {
          console.log("no jouney content available");
          if (noJourneyContent === false) {
            setNoJourneyContent(true);
            setOpen(true);
          }
        } else {
          if (userData.data.user.userRole.name !== "Student") {
            if (open === false) {
              console.log("userData.data", auth.jwt);
              console.log("userData.data.id", userData.data.user.id);
              localStorage.setItem("token", auth.jwt);
              localStorage.setItem("userId", userData.data.user.id);
              localStorage.setItem("userName", userData?.data?.user?.username);
              localStorage.setItem("sessionSetupTime",new Date());
              console.log("---------------called from google component1------------------")
              navigate("/user-profile/");
            }
          } else {
            console.log("no jouney content available");
            if (noJourneyContent === false) {
              setNoJourneyContent(true);
              setOpen(true);
            }
          }
        }
      } else {
        console.log("already have content");
        if (open === false) {
          if (
            !cookies.getItem("username") &&
            !cookies.getItem("autoLogin") &&
            !cookies.getItem("email") &&
            !cookies.getItem("n_email")
          ) {
            console.log(
              "loginnnnnnnnnnnnnnn cookiesssssssssssssssssssssss",
              userContent,
              "-------------"
            );
            // cookies.setItem('n_uid',  localStorage.getItem("userId"),'/');
            // cookies.setItem('n_email', userContent.email,'/');
            // cookies.setItem('n_username', (userContent.email).split('@')[0],'/');
            Cookie.set("n_uid", localStorage.getItem("userId"), {
              path: "/",
            });
            Cookie.set("n_email", userContent.email, {
              path: "/",
            });
            Cookie.set("n_username", userContent.email.split("@")[0], {
              path: "/",
            });
          }
          console.log("userData.data", auth.jwt);
          console.log("userData.data.id", userData.data.user.id);
          localStorage.setItem("token", auth.jwt);
          localStorage.setItem("userName",userData?.data?.user?.username)
          localStorage.setItem("userId", userData.data.user.id);
          localStorage.setItem("sessionSetupTime",new Date());
          console.log("---------------called from google component2------------------")
          navigate("/user-profile/");
        }
      }
    }
  }

  return (
    <div className="main-content">
      {userData.data ? (
        <>
          {setNoJourneyContent ? (
            <SelectRole
              open={open}
              setOpen={setOpen}
              userValue={userData.data.user}
            />
          ) : (
            <div className={"centered"}>
              <BounceIndicator size={25} color={"#0055a4"} />
            </div>
          )}
        </>
      ) : (
        <div className={"centered"}>
          <BounceIndicator size={25} color={"#0055a4"} />
        </div>
      )}
      {/* {auth && (
        <>
          <div>Jwt: {auth.jwt}</div>
          <div>User Id: {auth.user.id}</div>
          <div>Provider: {auth.user.provider}</div>
        </>
      )} */}
    </div>
  );
}
export default LoginRedirect;